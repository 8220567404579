export enum ChainId {
  MAINNET = 1,
  BSC_TEST = 97,
  SEPOLIA = 11155111,
  BASE = 8453,
}

// export const TEST_RPC_NODE =
// 'https://dry-little-tent.bsc-testnet.discover.quiknode.pro/84b8cf011376495b8a07b91c5e3868de9e58f08e/'
// 'https://rpc.ankr.com/bsc_testnet_chapel/128bdedab70a53096c6b5132d94384254aee84b8491502b928ab6c08652a7b78'
//
// export const TEST_RPC_NODE = 'https://mainnet.gateway.tenderly.co'
//
export const TEST_RPC_NODE = 'https://ethereum.publicnode.com'

export const LINK_ADDRESS = '0x514910771AF9Ca656af840dff83E8264EcF986CA'

export const NODE_ADDRESS = ['0xcf9c4337dcBFd7cBB71c2c5fb1b9e86edEcfb7C8']

export const K_LINE_API = 'https://eth-api.krav.trade/krav/v1/klines?symbol=BTCUSDT&interval=1d'

export const QUANTO_API = 'https://eth-api.krav.trade/krav/v1/list/quanto'

export const TRADE_HISTORY_API = 'https://eth-api.krav.trade/krav/v1/list/market'

export const DASHBOARD_OVERVIEW_API = 'https://eth-api.krav.trade/krav/v1/overview'

export const TEST_CHAIN_ID = ChainId.MAINNET

export const BTC_CONTRACT = '0x1b44F3514812d835EB1BDB0acB33d3fA3351Ee43'

export const BTC_PRICE_API = 'https://eth-api.krav.trade/krav/v1/price?symbol=BTCUSDT'

export const KRAV_STAKE = '0xe480d334e6BF7693b12982e9Bf116F3BEeD386a0'

export const KRAV_ADDRESS = '0x8b99c4DE6f3D396a9d4BBcAF9fA138F5393299FE'

export const DashBoard_USER_OVERVIEW_API = 'https://eth-api.krav.trade/krav/v1/user/asset/amount?account='

export const BASE_KRAV_TRADING_ADDRESS = '0x8975Fdbad4884998AC36669d126471cE239D94b1'
